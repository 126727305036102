import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay } from "swiper/core";
import Image from "../image/image.js";

import "swiper/swiper.scss";
import "./style.scss";

SwiperCore.use([Autoplay]);
SwiperCore.use([Pagination]);

const InfoSlider = ({ infoslides, staticTitle, image, alt }) => {
  return (
    <>
      <div className="columns infoSlider">
        <div className="container">
          <div className="columns">
            <div className="column is-6">
              <div className="container">
                {staticTitle && (
                  <div className="columns">
                    <div className="column is-12">
                      <p className="title">
                        <span className="green bold">{staticTitle}</span>
                      </p>
                    </div>
                  </div>
                )}
                <div className="columns">
                  <div className="column is-12">
                    <Swiper
                      spaceBetween={30}
                      slidesPerView={1}
                      loop={true}
                      speed={3000}
                      autoplay={{ delay: 5000, disableOnInteraction: false }}
                      pagination={{
                        clickable: true,
                      }}
                      className="mySwiper"
                    >
                      {infoslides.map((i) => (
                        <SwiperSlide>
                          <p>
                            {!staticTitle && (
                              <>
                                <br />
                                <br />
                                <span className="green bold">{i.title}</span>
                                <br />
                                <br />
                              </>
                            )}
                            {i.body}
                          </p>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-6">
              <div className="infoSliderImageContainer">
                <div className="infoSliderImage">
                  <Image filename={image} alt={alt} />
                </div>
              </div>
            </div>            
          </div>
          {/* <div className="section"/> */}
        </div>
      </div>
    </>
  );
};

export default InfoSlider;
