import React from "react";
import Header from "../components/header/header.js";
import CheckMark from "../assets/img/svg/check-mark.svg";
import InfoSlider from "../components/infoSlider/infoSlider.js";

import "../assets/styles/pages/_functionalities.scss";

const ALLFUNCTIONALITIES = [
  `Prenotazione in 7 lingue (italiano, inglese, tedesco, francese, russo, spagnolo, portoghese)`,
  `Combinazione dei piani tariffari per offrire sempre il miglior prezzo`,
  `Personalizzazione del CSS, testi e notifiche`,
  `Diverse possibilità di pagamento`,
  `Calendario di ricerca personalizzato (utilizzo gratuito di iCal per Google)`,
  `Gestione avanzata delle statistiche di navigazione, ricerca e produzione`,
  `Soluzioni con cambio camera`,
  `Integrazione con più di 300 canali B2B, B2C e GDS`,
  `Recovery strategy tool per mostrare all’utente messaggi pop-up che scoraggiano l’abbandono del sito`,
  `Integrazione campagne metamotori`,
  `Calendario tariffe online ti consente un lavoro giornaliero piacevole e user friendly utile per il controllo dei prezzi disponibili, e con aggiornamento automatico`,
  `Regole e variabili per il calcolo automatico di disponibilità e prezzi con variazione in riduzione o supplemento in € o in % sulle OTA`,
  `Landing page offerte personalizzate`,
  `PMS integrato`,
  `Integrazione nel sito della struttura ricettiva semplice e veloce. Documentazione tecnica fornita al momento dell’attivazione`,
  `Campagne promo: Possibilità di generare codici promozionali con validità temporale da comunicare a agenzie, mailing list o nella propria pagina Facebook`,
  `Statistica sugli step e relativo tasso di conversione: grafico disponibile online`,
];

const INFOSLIDES = [
  {
    title: `Eviti il rischio di overbooking`,
    body: `Se il channel manager aggiorna la disponibilità in tempo reale, in tutti i tuoi canali di vendita, il rischio di vendere due o più volte la stessa camera svanisce. Mai più brutte figure con i tuoi ospiti.`,
  },
  {
    title: `Risparmi tempo e migliori la tua produttività`,
    body: `Senza un channel manager, dovresti entrare nell’extranet di ogni OTA per aggiornare a mano tariffe e disponibilità.
    
    Aggiornare a mano prezzi e disponibilità delle camere è un’operazione ripetitiva, ad alto tasso di errore: basta una svista, magari uno zero in meno alla fine, e rischi di svendere un soggiorno. Quindi, è buona norma affidare questa routine a un processo automatizzato. In questo modo, avrai più tempo da dedicare a operazioni complesse e a migliorare l’esperienza del soggiorno dei tuoi ospiti.`,
  },
  {
    title: `Puoi ricevere più prenotazioni`,
    body: `Se affidi l’aggiornamento del tuo inventario a un solo strumento, puoi permetterti di aprire la disponibilità senza limitare il numero di camere in vendita nei vari canali o, al contrario, puoi gestire gli allotment senza rischio di errore – nel caso uno o più portali li preveda. Evitando di avere troppo invenduto.
    In più, considera che a beneficiarne è pure la visibilità del tuo hotel: una presenza ampia, in più portali di prenotazione, dà modo di far conoscere la tua struttura ricettiva anche in quei mercati lontani che, altrimenti, sarebbero difficili da raggiungere.
    
    Infine, la distribuzione diffusa evita l’eccessiva dipendenza del tuo fatturato da una sola fonte di prenotazioni, per esempio da un solo portale.`,   
  },
  {
    title: `Puoi fare revenue come si deve`,
    body: `Se adotti una strategia di prezzi dinamici, un channel manager è essenziale. Sarà questo strumento ad aggiornare per te le tariffe in tutti i canali. In questo modo, hai la possibilità di trarre il massimo profitto possibile dalla tua strategia di marketing.`,
  }  
];

const Functionalities = () => {
  return (
    <>
      <Header
        title="Funzionalità"
        metaDescription="Ti consente di gestire in modo rapido ed automatico tutti i portali di distribuzione online da un solo back-office."
      />
      <div className="container functionalities">
        {ALLFUNCTIONALITIES.map((i) => (
          <div className="columns">
            <div className="column is-12">
              <img className="functionalitiesImg" src={CheckMark} alt="" />
              <p className="description">{i}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="section" />
      <InfoSlider infoslides={INFOSLIDES} image={"bam-monitor"} alt={"Book@Me Desktop View"} />
      <div className="section" />
    </>
  );
};

export default Functionalities;
